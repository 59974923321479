import React from "react";
import { connect, useSelector } from "react-redux";
import {
  ForexQuery,
  IndicesQuery,
  CommoditiesEnergyQuery,
  MetalsQuery,
  AllMarkets,
} from "../../prismic/staticQueries";
import { multiTableDataMapper } from "../../utils/helpers";

import Layout from "../../layout";
import {
  HeadingBanner,
  ContentTableMultiViewMore,
  Subscription,
} from "../../components/common";
import BANNER from "../../images/siteImages/forex/MARKETS_BANNER.jpg";
import PaymentSlider from "../Company/PaymentSlider";

const getMenClicked = (state) => {
  if (!state) return "";
  if (!state.params) return "";
  if (!state.params[0]) return "";
  return state.params.text;
};

const MarketsHome = (props) => {
  const menuClicled = getMenClicked(props.location.state);

  const refs = [];
  refs.push(React.useRef(null));
  refs.push(React.useRef(null));
  refs.push(React.useRef(null));
  refs.push(React.useRef(null));

  React.useEffect(() => {
    try {
      if (menuClicled === "forex")
        refs.current.scrollIntoView({ behavior: "smooth" });
      if (menuClicled === "indices")
        refs[1].current.scrollIntoView({ behavior: "smooth" });
      if (menuClicled === "commodities")
        refs[2].current.scrollIntoView({ behavior: "smooth" });
      if (menuClicled === "metals")
        refs[3].current.scrollIntoView({ behavior: "smooth" });
    } catch (error) {}
  });

  const language = useSelector((state) => state.language);

  const forexData = ForexQuery(language);
  const indicesData = IndicesQuery(language);
  const commoditiesData = CommoditiesEnergyQuery(language);
  const metalsData = MetalsQuery(language);

  const totalTables = [forexData, indicesData, commoditiesData, metalsData];

  const forexTables = multiTableDataMapper(forexData.table);
  const indicesTables = multiTableDataMapper(indicesData.table);
  const commoditiesTables = multiTableDataMapper(commoditiesData.table);
  const metalsTables = multiTableDataMapper(metalsData.table);

  const tablesData = [];

  tablesData.push(forexTables);
  tablesData.push(indicesTables);
  tablesData.push(commoditiesTables);
  tablesData.push(metalsTables);

  // tablesData.push(tableDataMapper(indicesData.table))
  // tablesData.push(tableDataMapper(commoditiesData.table))
  // tablesData.push(tableDataMapper(metalsData.table))

  const [accountSelected, setAccountSelected] = React.useState(0);

  const allMarkets = AllMarkets(language);
  return (
    <Layout>
      <HeadingBanner
        heading={allMarkets.page_heading}
        buttons={allMarkets.main_buttons}
        banner_image={BANNER}
      />
      <section className="job-contents p-top-20 p-bottom-65">
        <div className="container">
          <div className="row">
            {allMarkets.markets_box.map((obj, index) => (
              <>
                <div className="col-lg-12" ref={refs[index]}>
                  {/* <h3 className="text-center pt-5">{obj.title.text}</h3> */}

                  {index < 4 && (
                    <ContentTableMultiViewMore
                      tableId={index}
                      accountSelected={accountSelected}
                      setAccountSelected={setAccountSelected}
                      title={obj.title.text}
                      data={tablesData[index]}
                      totalTables={totalTables[index].total_tables}
                      viewMoreLabel={obj.view_all_label}
                      viewMoreLink={obj.view_all_link}
                    />
                  )}

                  {/* {index > 0 && <div className="shadowed-container mx-3 my-5">
                          <ContentTableViewMore title={obj.title.text}  header={tablesData[index][0]} body={tablesData[index][1]} viewMoreLabel={obj.view_all_label} viewMoreLink={obj.view_all_link} />
                        </div>} */}
                </div>
              </>
            ))}
          </div>
        </div>
      </section>
      
    </Layout>
  );
};

const mapStateToProps = (state) => ({ language: state.language });

export default connect(mapStateToProps)(MarketsHome);
